<template>
  <view-item title="员工业绩报表">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="员工提成" name="statistics">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="statistics.table.daily" :model="statistics.form.data">
                    <en-form-item label="结算日期">
                      <en-date-picker
                        v-model:start="statistics.form.data.settlementStartDate"
                        v-model:end="statistics.form.data.settlementEndDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                    <en-form-item label="结清日期">
                      <en-date-picker
                        v-model:start="statistics.form.data.settleStartDate"
                        v-model:end="statistics.form.data.settleEndDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                  </form-query>
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="USRCOMSFD"
                        :height="height"
                        :data="statistics.table.data"
                        :loading="statistics.table.loading"
                        pagination
                        :paging="statistics.table.paging"
                        :method="statistics.form.data"
                        show-summary
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                        <template #COMMISSION="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
                          <en-button type="primary" link @click="statistics.table.operation.commission.click(row)">{{
                            row.advisorBonusAmount
                          }}</en-button>
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
            <en-tab-pane label="员工提成明细" name="indetail">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="indetail.table.daily" :model="indetail.form.data">
                    <en-form-item label="结清日期">
                      <en-date-picker
                        v-model:start="indetail.form.data.settleStartDate"
                        v-model:end="indetail.form.data.settleEndDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                    <en-form-item label="人员" prop="advisorId">
                      <select-maintain
                        v-model="indetail.form.data.advisorId"
                        :ajax="{
                          action: 'GET /enocloud/common/user',
                          params: (params, value) => (params.payload = { name: value })
                        }"
                        :props="{ label: 'name', value: 'id' }"
                        clearable
                        remote
                      ></select-maintain>
                    </en-form-item>
                  </form-query>
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="USRCOMSDFD"
                        :height="height"
                        :data="indetail.table.data"
                        :loading="indetail.table.loading"
                        pagination
                        :paging="indetail.table.paging"
                        :method="indetail.form.data.mode"
                        show-summary
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
  <en-dialog v-model="comdetail.visible" width="80%">
    <form-query :model="comdetail.form.data" :method="comdetail.table.get">
      <en-form-item label="统计日期">
        <en-date-picker
          v-model:start="comdetail.form.data.settleStartDate"
          v-model:end="comdetail.form.data.settleEndDate"
          type="daterange"
          value-format="YYYY-MM-DD"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="人员" prop="advisorId">
        <select-maintain
          v-model="comdetail.form.data.advisorId"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: 'id' }"
          clearable
          remote
        ></select-maintain>
      </en-form-item>
    </form-query>
    <table-dynamic
      :height="600"
      code="USRCOMSDFD"
      :data="comdetail.table.data"
      :loading="comdetail.table.loading"
      :paging="comdetail.table.paging"
      :method="comdetail.table.get"
      :summary="comdetail.table.summary.data"
      show-summary
      pagination
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { omit, pick } from 'lodash-es'

export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              total: {
                action: 'GET /enocloud/statement/bonus/summary/export',
                params(params) {
                  params.payload = omit(this.statistics.form.data, 'mode')
                }
              },
              detail: {
                action: 'GET /enocloud/statement/bonus/advisor/export'
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'statistics':
                  this.operation.export.total()
                  this.store.openDownload()
                  break
                case 'indetail':
                  this.operation.export.detail()
                  this.store.openDownload()
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'statistics'
      },
      statistics: {
        children: {
          form: {
            data: {
              settlementStartDate: '',
              settlementEndDate: '',
              settleStartDate: '',
              settleEndDate: '',
              advisorId: ''
            }
          },
          table: {
            children: {
              operation: {
                commission: {
                  click(row: EnocloudStatementDefinitions['ServiceQueryDto']) {
                    this.comdetail.form.data.id = row.id
                    this.comdetail.table.get()
                    this.comdetail.visible = true
                  }
                }
              }
            },

            ajax: {
              daily: {
                action: 'GET /enocloud/statement/bonus/summary',
                summay: 'GET /statement/bonus/summary/total',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(
                    this.statistics.form.data,
                    'settlementStartDate',
                    'settlementEndDate',
                    'settleStartDate',
                    'settleEndDate',
                    'branchIds',
                    'settlementProgressCode'
                  )
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/service/monthly',
                summary: 'GET /enocloud/statement/service/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.statistics.form.data, 'endDate', 'startDate', 'branchIds', 'settlementProgressCode')
                }
              }
            }
          }
        }
      },
      comdetail: {
        visible: false,
        children: {
          form: {
            data: {
              settlementStartDate: '',
              settlementEndDate: '',
              settleStartDate: '',
              settleEndDate: '',
              advisorId: '',
              branchIds: []
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/statement/bonus/advisor/detail',
                summary: 'GET /enocloud/statement/bonus/advisor/detail/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.comdetail.form.data
                }
              }
            }
          }
        }
      },
      indetail: {
        children: {
          form: {
            is: 'form',
            data: {
              settleStartDate: '',
              settleEndDate: '',
              branchIds: [],
              advisorId: ''
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/bonus/advisor/detail',
                summary: 'GET /enocloud/statement/bonus/advisor/detail/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.indetail.form.data, 'settleStartDate', 'settleEndDate', 'advisorId', 'branchIds')
                }
              }
            }
          },
          config: {
            SETTLEMENT_DATETIME: {
              header: { filter: { type: 'date', field: ['settlementStartDate', 'settlementEndDate'], props: { type: 'daterange' } } }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.statistics.table.daily()
    this.indetail.table.daily()
  }
})
</script>
